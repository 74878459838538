@font-face {
  font-family: 'OpenSans-Regular';
  /* src: url(../fonts/OpenSans-Regular.ttf) format('truetype'); */
  src: url(/public/font/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url(/public/font/OpenSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Acme-Regular';
  src: url(/public/font/Acme-Regular.ttf) format('truetype');
}

* {
  margin: 0px;
  padding: 0px;
}




body {
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  background: white;
  outline: none;
}

a {
  text-decoration: none !important;
  color: inherit;
  outline: none
}

a:hover, a:focus, a:active {
  color: #c6d7fb;
  text-decoration: underline;
}

h1, h2, h3, h4 {
  font-family: 'Acme', sans-serif;
}

p {
  text-align: justify;
  line-height: 32px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin-bottom: 25px;
}

.no-pad {
  padding-left: 0px;
  padding-right: 0px;
}

.no-mar {
  margin-left: 0px;
  margin-right: 0px;
}
.popup.show{
  opacity: 1;
}
.popup.show .modal-dialog.modal-dialog-centered { 
    align-items: center;
    justify-content: center;
    margin: auto; 
    transform: translate(0, 0%);
    top: 0%;
} 

.blink {
  width: 100%;
  margin: 0 auto;
  color: #313131;
  font-weight: bold;
  -webkit-animation: colorchange 5s infinite alternate;
}

@-webkit-keyframes colorchange {
  0% {
      color: #e1fe03;
  }

  10% {
      color: #ff6200;
  }

  20% {
      color: #8abcf5;
  }
}

#pportal {
  /* background: url(https://webapi.entab.info/api/image/AFGJI/public/Images/Campulogo.png) no-repeat right top; */
  background: url(https://webapi.entab.info/api/image/AFGJI/public/Images/Campulogo.png) no-repeat right top;
  width: 110px;
  height: 88px;
  position: fixed;
  top: 38%;
  right: -2px;
  z-index: 999;
}

.no-img {
  background-image: none !important;
}

.onlinepay {
  position: absolute;
  z-index: 1;
  top: 220px;
  left: 0px;
}

.onlinepay a {
  color: #fff;
  animation: blinker2 1s linear infinite;
  background: #22264a;
  padding: 7px 9px;
  line-height: 50px;
  font-size: 16px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

@keyframes blinker2 {
  50% {
      background: #ff0000;
  }
}

.rec-box {
  display: flex;
  flex-wrap: wrap;
}

.rec-box .cards a {
  color: black;
  display: flex;
  align-items: center;
}

.rec-box .cards p {
  color: black;
  margin: 0;
  text-align: left;
  line-height: 20px;
}

.rec-box .cards {
  width: 280px;
  box-shadow: 1px 1px 3px grey;
  margin: 0 0 12px 10px;
  background: #efefef;
  padding: 15px 5px;
}

.rec-box .cards:hover a p {
  color: #36acf9;
}

/*************************OnlineR*************************/
.OnlineFee2 {
  animation: blinkingBackground 2s infinite;
  width: 158px;
  position: fixed;
  top: 320px;
  left: -5px;
  z-index: 9;
  background: #fd2327;
  color: white;
  line-height: 23px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid white;
  cursor: pointer;
  padding: 4px;
  display: none;
}

.OnlineFee {
  animation: blinkingBackground 2s infinite;
  width: 158px;
  position: fixed;
  top: 246px;
  left: -5px;
  z-index: 9;
  background: #fd2327;
  color: white;
  line-height: 23px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid white;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
  padding: 4px;
}

.OnlineFee a {
  color: white;
}

@-webkit-keyframes bounce1 {
  0% {
      background-color: red;
  }

  33% {
      background-color: #d69d34;
  }

  66% {
      background-color: red;
  }

  100% {
      background-color: #d69d34;
  }
}

@keyframes bounce1 {
  0% {
      background-color: #ff0000;
      transition: 0.8s;
  }

  33% {
      background-color: #008f3a;
      transition: 0.8s;
  }

  66% {
      background-color: #ff0000;
      transition: 0.8s;
  }

  100% {
      background-color: #ff0000;
      transition: 0.8s;
  }
}

.OnlineFee:hover {
  color: white !important;
}

.OnlineFee a:focus {
  color: white !important;
}

.OnlineFee a:active {
  color: white !important;
}
.emblem_icon {
  width: 280px;
  max-width: 90%;
}
/****Parent*****/
.erp_links {
  right: 2px;
  position: fixed;
  top: 40px;
  z-index: 999;
}

.erp_links img {
  width: 75px;
}

.modal-dialog {
  width: 600px;
}

.modal-dialog {
  animation: mymove 3s;
  transition: all 3s;
}

.modal-open .modal {
  padding-right: 0px !important;
}

.modal-body {
  padding: 0px;
}

.modal-body p {
  margin: 0px 10px;
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1.8em;
  text-overflow: ellipsis;
}

.modal {
  background: rgba(0, 0, 0, 0.73) !important;
  z-index: 99999999 !important;
}

.modal-body img {
  height: 450px !Important;
  width: 100%;
  object-fit: contain;
  object-position: center;
  background: #efefef;
}

.modal-header .close {
  margin-top: -2px;
  color: #fff;
  opacity: 9;
  right: 8px;
  font-size: 25px;
}

.modal-header {
  display: block;
  background-image: linear-gradient(135deg, #1e458a 0%, #0f51c7 100%);
}

.modal-header h4 {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1.4em;
  text-overflow: ellipsis;
}

.carousel-control.right, .carousel-control.left {
  background-image: inherit;
}

.carousel-control {
  top: 50px;
}

.downloadarow {
  border: solid 1px #4e9ed2;
  padding: 5px;
}

/*----------------------------------------------Space Manage--------------------------------------------*/
audio, canvas, progress, video {
  display: block;
  vertical-align: baseline;
  margin: 0 auto;
  width: 100%;
}

.top-pad-5 {
  padding-top: 5px;
}

.top-pad-10 {
  padding-top: 10px;
}

.top-pad-20 {
  padding-top: 20px;
}

.top-pad-30 {
  padding-top: 30px;
}

.top-pad-40 {
  padding-top: 40px;
}

.top-pad-100 {
  padding-top: 100px;
}

.top-pad-60 {
  padding-top: 60px;
}

.top-pad-mobile {
  padding-top: 60px;
}

.tb-pad-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tb-pad-20 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.tb-pad-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.tb-pad-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.tb-pad-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.tb-pad-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.top-pad-30 {
  padding-top: 30px;
}

.top-mar-10 {
  margin-top: 10px
}

.top-mar-20 {
  margin-top: 20px
}

.top-mar-30 {
  margin-top: 30px
}

.tb-mar-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tb-mar-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tb-mar-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.nopad {
  padding: 0px;
  margin: 0px;
}

.clr40 {
  height: 40px;
  clear: both;
}

.clr10 {
  height: 10px;
  clear: both;
}

.clr20 {
  height: 20px;
  clear: both;
}

.top-mar-60 {
  margin-top: -90px;
}

.tb-pad-100 {
  padding-top: 85px;
  padding-bottom: 85px;
}

.pad-right {
  padding-right: 0px;
}

.pt-115 {
  padding-top: 40px;
}

.pb-115 {
  padding-bottom: 40px;
}

/*****************Highlights******************/
.no-linehei {
  line-height: 0px;
}

.highlight {
  background: rgb(7,130,212);
  background: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
  color: #fff
}

.highlight p {
  line-height: 27px;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: #fff;
  display: inline-block;padding-left: 10px;padding-right:10px;
  border-right: solid 1px #fff;margin-right: 10px;
}

.highlight p a {
  color: #fff;
}

.marquee p a {
  color: white;
}

.highlight p i:hover {
  color: #ffbb00;
}

.body_height h4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

/*****************Online Reg******************/
.onreg {
  z-index: 9;
  background-color: #37c32f;
  color: white !important;
  font-size: 15px;
  text-align: center;
  padding: 7px 11px;
}

@keyframes blinker {
  50% {
      opacity: .9;
      background: red;
  }
}

.blink {
  animation: blinker 0.9s linear infinite;
}

.OnlineRe {
  position: absolute;
  margin: -30px auto;
  width: 100%;
}

.blink a {
  color: white
}

/*****************Navagation******************/
.toplink {
  list-style: none;
  float: right;
  margin-top: 16px;
  margin-bottom: 10px;
}

.toplink li {
  display: inline;
}

.toplink li a {
  color: #ffffff;
  font-size: 17px;
  text-align: right;
  padding-right: 15px;
}

.toplink li a:hover {
  color: #84cfff;
}

.toplink li i {
  font-size: 16px;
  color: #fff;
}

.address {
  text-align: right;
}
.table-bordered>thead>tr>th{
  border-bottom: 0px solid;
}
.address span {
  padding-top: 15px;
  padding-left: 9px;
  text-align: right;
  padding-right: 12px;
}

.address span i {
  color: #84cfff;
  padding-right: 6px;
}

.address span a {
  color: #84cfff;
  font-size: 16px;
}

.address span a:hover {
  color: #ffffff;
}

.boxs {
  padding: 60px 0px;
}

/*****************Menu******************/
.menu_sec.fix-header {
  position: fixed;
  width: 100%;
  z-index: 99;
  -webkit-transition: width 1s;
  transition: width 1s;
  transition: background 1s;
  top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background: rgb(7,130,212);
  background: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
}

.menu_sec {
  background: rgb(7,130,212);
  background: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
}

.submenu_left {
  display: block !important;
  left: -185px !important;
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 9px 7px;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  border-left: 1px solid #2d7caf;
  text-transform: uppercase;
  font-weight: 500;
}
.min-height-container{
  min-height: 400px;
  padding: 30px 0
}
a#specialWing {
  background: #cf9217;
}
.caret {
  vertical-align: middle;
  border-top: 3px dashed;
  border-top: 3px solid;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
}

.menu .nav > li > a, .dropdown-menu > li > a {
  color: #fffefe;
  font-size: 15px;
}

.menu .nav > li > a:hover, .nav > li > a:focus {
  background-color: #ffd5b4;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background-color: rgb(27 127 193);
  color: #ffffff;
}

.dropdown-submenu > a:after {
  border-left-color: #7e0203 !important;
}

.dropdown-submenu > a:hover {
  border-left-color: #7e0203 !important;
}

.dropdown-menu {
  background: rgb(1 94 157);
}

.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;
  background-color: transparent;
  color: #ffffff;
  background: #066db1;
}

.navbar-toggle {
  border: solid 1px #fff;
  margin-top: -75px;
  background-color: #0975b9;
}

.navbar-toggle .icon-bar {
  border: solid 1px #fff;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #555;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  color: #ffffff;
  background: rgb(78, 158, 210);
}

.dropdown-submenu ul {
  display: none !important;
}

.dropdown-submenu:hover ul {
  display: block !important;
}

.slider {
  height: 530px !important;
}

/*****************news******************/
.newbox {
  text-transform: uppercase;
}

.news_area {
  background: #192b53;
  padding: 0px;
  border-bottom: 4px solid #0180d4;
  background: rgb(25,43,83);
  background: linear-gradient(333deg, rgba(25,43,83,1) 0%, rgba(36,70,145,1) 100%);
}

.newbox h3 {
  margin-top: 5px;
  color: #ffffff;
  font-size: 16px;
  line-height: 30px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 2em;
}

.newbox h4 {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #565656;
  font-weight: normal;
  text-align: center;
}

.newbox i {
  padding-right: 10px;
}

hr {
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.21);
}

.newbox h3 a {
  color: #9abeff;
}

.news_area h4 {
  font-size: 22px;
  margin-top: 16px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 6px;
}
.Circular-space {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.newbox h2 {
  font-size: 17px;
  color: #b6d1ff;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 1em;
}

.circularbox h3 {
  margin-top: 6px;
  color: #3c3c3c;
  font-size: 16px;
  line-height: 31px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 2.2em;
}

.circularbox h3 a {
  color: #306ad0;
  padding-right: 9px;
}

.circularbox {
  background: #d1e8ff;
  padding: 7.8px 6px;
}

.circularbox h4 {
  font-size: 22px;
  margin-top: 16px;
  text-align: center;
  color: #192b53;
  margin-bottom: 1px;
}

.about-tran {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  height: 13em;
  text-overflow: ellipsis;
  margin-bottom: 12px;
  line-height: 26px;
}

/*****************Box******************/
.grow-2 {
  transition: all .4s ease-in-out;
  border: solid 2px #579ce2;
  width: 150px;
  height: 150px;
  border-radius: 10px;
}

.grow-2:hover {
  transform: scale(1.1);
  transform-style: preserve-3d;
  box-shadow: 1px 2px 5px 2px #979797;
}

.grow-1 {
  transition: all .4s ease-in-out;
  border: solid 2px #3666ba;
  width: 120px;
  height: 120px;
}

.grow-1:hover {
  transform: scale(1.1);
  transform-style: preserve-3d;
  box-shadow: 1px 2px 5px 2px #979797;
}

.bgimage {
  background: url(https://webapi.entab.info/api/image/AFGJI/public/Images/bg-2.jpg) top fixed;
  padding: 10px 0px;
}

.topperbg {
  background: url(https://webapi.entab.info/api/image/AFGJI/public/Images/topbg.jpg) no-repeat center fixed;
  padding: 50px 0px;
  background-color: #27427d;
}

.toppersec {
  margin-top: 10px;
}

.box_1 {
  background: #133673;
  padding: 25px;
  border-radius: 100%;
  width: 170px;
  height: 170px;
  margin: 10px auto;
}

.box_2 {
  background: #2553a5;
  padding: 25px;
  border-radius: 100%;
  width: 170px;
  height: 170px;
  margin: 10px auto;
}

.box_3 {
  background: #133673;
  padding: 25px;
  border-radius: 100%;
  width: 170px;
  height: 170px;
  margin: 10px auto;
}

.box_4 {
  background: #306ad0;
  padding: 25px;
  border-radius: 100%;
  width: 170px;
  height: 170px;
  margin: 10px auto;
}

.boxs h3 {
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  margin-top: 0px;
  line-height: 20px;
  padding: 6px;
  margin-bottom: 0px;
}

.boxs i {
  text-align: center;
  color: #ffffff;
  font-size: 40px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 12px;
}

.main_head span {
  color: #215dc9;
}

.graybg {
  background: #d1e8ff;
  padding: 10px;
  padding-bottom: 30px;
}

.bluebg {
  background: #0180d4;
  padding: 10px;
  padding-bottom: 30px;
  background: rgb(4,119,195);
  background: linear-gradient(25deg, rgba(4,119,195,1) 0%, rgba(5,97,157,1) 100%);
}

.white {
  color: white;
}

.bluebg p {
  color: white;
}

.pad_box_left {
  padding-left: 80px;
  padding-right: 45px;
}

.pad_box_right {
  padding-right: 80px;
  padding-left: 45px;
}

.pad_box_right img {
  object-fit: contain;
  height: 270px;

}
.pad_box_left img {
  object-fit: contain;
  height: 270px;

}

.main_head {
  margin-bottom: 19px;
  font-weight: 600;
}

.img_right {
  float: right;
  width: 354px;
  height: 211px;
  padding-left: 19px;
  padding-top: 10px;
}

.img_left {
  float: left;
  width: 354px;
  height: 211px;
  padding-left: 19px;
  padding-top: 10px;
}

.main_head i {
  float: right;
}

.main_head a {
  color: #306ad0;
}

/****CATEGORY****/
.category-text h2 {
  font-size: 36px;
  color: #fff;
  padding: 0 50px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.height-100 {
  height: 100px;
}

.single-category {
  padding: 20px 0;
  border-radius: 100px;
  display: block;
}

.single-category .icon {
  display: inline-block;
}

.single-category .cont {
  display: block;
}

.single-category .cont span {
  color: #fff;
  font-size: 18px;
  padding-top: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

.color-1 {
  background-color: #062150;
}

.color-2 {
  background-color: #133673;
}

.color-3 {
  background-color: #2553a5;
}

.color-4 {
  background-color: #306ad0;
}

.pt-40 {
  padding-top: 80px;
}

.mt-40 {
  margin-top: 70px;
}

.pb-80 {
  padding-bottom: 80px;
}

.color-1:hover {
  background: #143c82;
}

.color-2:hover {
  background: #2d68d0;
}

.color-3:hover {
  background: #0f5ade;
}

.color-4:hover {
  background: #4f89ef;
}

.pt-80 {
  padding-top: 50px;
}

.pb-80 {
  padding-bottom: 50px;
}

/******topper********/
[data-overlay] {
  position: relative;
}

[data-overlay]::before {
  background: #07294d none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

[data-overlay="3"]::before {
  opacity: 0.3;
}

[data-overlay="4"]::before {
  opacity: 0.4;
}

[data-overlay="5"]::before {
  opacity: 0.5;
}

[data-overlay="6"]::before {
  opacity: 0.6;
}

[data-overlay="7"]::before {
  opacity: 0.7;
}

[data-overlay="8"]::before {
  opacity: 0.8;
}

[data-overlay="9"]::before {
  opacity: 0.9;
}

.testimonial-slide {
  position: relative;
  z-index: 5;
}

.toppersec h4 {
  color: white;
  font-size: 18px;
  margin-bottom: 7px;
  margin-top: 7px;
  transition: all 0.4s linear;
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 1;
  height: 1.5em;
  text-overflow: ellipsis;
}

.toppersec h5 {
  color: #95d5ff;
  font-size: 15px;
  margin: 2px 0px 2px 0px;
  transition: all 0.4s linear;
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-boxorient: vertical;
  height: 1.2em;
  text-overflow: ellipsis;
}

.toppersec img {
  width: 110px !important;
  height: 110px;
  margin: 0 auto;
  border-radius: 10px;
  border: 3px solid #ffffff;
  object-fit: cover;
  object-position: top;
  background: #c6c5c3;
}

.toppersec h6 {
  color: #ffc013;
  font-size: 15px;
  margin: 4px 0px 9px 0px;
  transition: all 0.4s linear;
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 1;
  -webkit-boxorient: vertical;
  height: 1.2em;
  text-overflow: ellipsis;
}

/*****Gallery*******/
.gal_1 {
  height: 360px;
  object-position: top;
  object-fit: cover;
  width: 100%;
  padding: 4px;
  transition: .5s;
}

.gal_2 {
  height: 220px;
  object-position: center;
  object-fit: cover;
  width: 100%;
  /* padding: 4px; */
  transition: .5s;
  background: #f9f9f9;
  border: solid 5px white;
  /* border-radius: 10px; */
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(25, 43, 83, 0.8784313725490196);
  background: #c97608d9;
  /* margin: 10px; */
  border: solid 5px white;
  /* border-radius: 10px; */
}

.galoverlay:hover .overlay {
  opacity: 1;
}

.overlay .text {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  outline: none;
  font-weight: normal;
}

.overlay-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  width: 120px;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(19, 69, 156, 0.71);
  margin: 0 auto;
  border-radius: 10px;
  background: #c97608d9;
}

.galoverlay-box:hover .overlay-box {
  opacity: 1;
}

.overlay-box .text {
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  outline: none;
  font-weight: normal;
}

.more {
  color: #000;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 8px 12px;
  border: solid 1px #c3d7f5;
}

.more:hover {
  color: #fffefe;
  background-color: #2d63bf;
  border-radius: 0px;
  padding: 4px 10px;
  border: solid 2px #ffffff;
}

.more:active {
  color: #fffefe;
  background-color: #2d63bf;
  border-color: #2d63bf;
  border-radius: 0px;
  padding: 4px 10px;
}

.more:focus {
  color: #fffefe;
  background-color: #2d63bf;
  border-color: #2d63bf;
  border-radius: 0px;
  padding: 4px 10px;
}

/*****Footer*******/
.footer_sec {
  padding-top: 45px;
  padding-bottom: 10px;
  background: rgb(25,43,83);
  background: linear-gradient(333deg, rgba(25,43,83,1) 0%, rgba(36,70,145,1) 100%);
}

.footer_sec h3 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 14px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.footer_sec ul {
  text-align: center;
}

.footer_sec ul li {
  text-align: center;
  list-style-type: none;
  line-height: 25px;
}

.footer_sec ul li a {
  color: #c6d7fb;
  font-size: 16px;
  line-height: 29px;
}

.footer_sec ul li a:hover {
  color: #7cbdff;
}

.footer_sec p {
  color: #c6d7fb;
  text-align: center;
}

/*****Mobile App*******/
.roundimg {
  transition: all .4s ease-in-out;
  border: solid 2px #ffffff;
  width: 120px;
  height: 120px;    border-radius: 10px;object-fit: contain;
  background: #d9d9d9;
}

.space {
  padding-bottom: 15px;
}
.lazyload-wrapper {
  display: unset;
}
/*****Mobile App*******/
.footer_sec_bottom {
  background: #0782d4;
  background: rgb(7,130,212);
  background: linear-gradient(79deg, rgba(7,130,212,1) 0%, rgba(36,70,145,1) 100%);
}

.footer_sec_bottom p {
  text-align: center;
  line-height: 28px;
  margin-bottom: 0px;
  font-size: 13px;
  color: #ffffff;
  font-weight: normal;
}

.footer_sec_bottom a {
  color: #ffffff;
}

.footer_sec_bottom a:hover {
  color: #ffe200;
}

.navbar-collapse {
  position: relative;
  z-index: 999;
}

/*****innerpage design*******/
.body_height {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 700px;
}

.innerhead {
  font-size: 27px;
  color: #333;
  padding: 12px 0px;
  margin-bottom: 19px;
  font-weight: 600;
  font-size: 30px;
}

.innerbg {
  background: #efefef;
}

.breadcrumb {
  margin: 0;
  background-color: transparent;
  text-transform: capitalize;
  line-height: 59px;
  float: right;
}

.breadcrumb li {
  display: inline-block;
}

.breadcrumb li a {
  color: #1b1c1d
}

.breadcrumb li a:hover {
  color: #54aee8;
}

.breadcrumb li:last-child {
  pointer-events: none;
}

.breadcrumb li .active {
  color: #ff0707 !important;
}

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #67b7ea;
  content: "/\00a0";
}

.team {
  height: 260px;
  padding-bottom: 45px;
}

.team h5 {
  color: #4aa4de;
  font-size: 18px;
  margin-bottom: 5px;
}

.team h6 {
  color: #676262;
  font-size: 16px;
  line-height: 23px;
}

.team img {
  height: 140px;
  width: 140px;
  border: solid 1px #eaeaea;
  border-radius: 10%;
}

.subhead {
  font-size: 25px;
  color: #4aa4de;
  margin-top: 0px;
  margin-bottom: 15px;
}

.tablescroll {
  overflow-x: auto;
}

td {
  border: 1px solid #ddd;
}

table.table.table-striped {
  width: 100%;
}

.table-responsive {
  overflow: hidden;
}

table.table.table-striped thead {
  background: #6cb7e7;
}

.checkList {
  padding: 3px 0 10px;
  list-style: none;
}

.checkList li {
  background: url(https://webapi.entab.info/api/image/AFGJI/public/Images/check.png) no-repeat 0 4px;
  padding: 1px 0 2px 24px;
  margin-top: 0px;
  text-align: justify;
  line-height: 28px;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

.checkList li a {
  color: black;
}

.checkList li a:hover {
  color: #ebaa00;
}

.checkListRec {
  padding: 3px 0 10px;
  list-style: none;
}

.checkListRec li {
  text-transform: uppercase;
  background: url(https://webapi.entab.info/api/image/AFGJI/public/Images/check.png) no-repeat 0 4px;
  padding: 1px 0 2px 24px;
  margin-top: 0px;
  text-align: justify;
  line-height: 28px;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

.checkListRec li a {
  color: black;
}

.checkListRec li a:hover {
  color: #ebaa00;
}

.viewall {
  color: #2e86cc;
  line-height: 20px;
  padding: 3px;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 12px;
}

.viewall:hover {
  color: #174366;
}

.link_color a {
  color: #037bc9;
}

.link_color a:hover {
  color: #0196f7;
}

.button-default {
  background: #3491ce none repeat scroll 0 0;
  color: #ffffff;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 10px 30px;
  text-transform: uppercase;
  border-radius: 50px;
}

.button-default:hover {
  background: #4ea7e0 none repeat scroll 0 0;
  color: #ffffff;
}

.button-default:focus, .button-default:focus {
  background: #b10407 none repeat scroll 0 0;
  color: #ffffff;
}

.img_space {
  padding-top: 20px;
}

.black-bold {
  float: right;
}

.dips-grid a {
  color: black;
}

.dips-grid a:hover {
  color: #005919;
}

.panel-ppad {
  padding-top: 0px;
  font-weight: bold;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.3em;
  text-overflow: ellipsis;
  line-height: 19px;
  text-align: left;
  padding: 0;
      margin-bottom: 0px;
}

/*****Gallery*******/
.myDiv {
  display: none;
}

.selected_year {
  display: block;
}

#ddlYear {
  border: solid 1px #4aa4de;
  color: #4aa4de;
  width: 140px;
  height: 31px;
  padding: 5px;
  margin-top: 18px;
  float: right;
}

.galbg p {
  text-align: center;
  font-size: 16px;
  font-family: 'Amaranth-Regular', sans-serif;
  color: #737373;
}

.galbg h5 {
  background: #e2e2e2;
  width: 120px;
  color: black;
  padding: 4px 10px;
  font-size: 15px;
  border-bottom: 2px solid grey;
}

.gal-cate h3 {
  color: #272727;
  text-align: center;
  font-size: 16px;
  margin-bottom: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1.4em;
  line-height: 18px;
  text-overflow: ellipsis;
  margin-top: 0px;
  font-family: PT_Sans-Web-Regular;
}

.gal-img {
  object-fit: fill;
  object-position: top;
  width: 95%;
  height: 150px;
  cursor: pointer;
  margin-bottom: 15px;
  background: linear-gradient(to top, #ddd -4%, #fbfdf4 100%);
  padding: 3px;
  box-shadow: 1px 1px 3px 0px #828282;
}

.gallerygrid {
  width: 25%;
  padding: 0px 10px;
}

.gal-cate img {
  object-fit: fill;
  object-position: top;
  width: 100%;
  height: 170px;
  cursor: pointer;
  background: #ddd;
}

.galbg h5 {
  color: white;
  text-align: center;
  font-size: 15px;
  line-height: 16px;
  padding: 3px;
  background: #4aa4de;
  width: 28%;
  bottom: 0px;
  position: absolute;
  border: 1px solid #e0e0e0;
}
 
.gal-cate h4 {
  color: #272727;
  text-align: center;
  font-size: 15px;
  margin-bottom: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1.2em;
  line-height: 16px;
  text-overflow: ellipsis;
  margin-top: 15px;
}
.no-pad.galoverlay {
  position: relative;
}
.event_blk {
  width: 94% !important;
}
.column {
  float: left;
  width: 25%;
  position: relative;
  padding: 0 5px;
}

.dnld {
  position: inherit;
  text-align: center;
  width: 35px;
  margin: 5px auto;
  top: -20px;
  border-radius: 50%;
  box-shadow: 1px 2px 1px 0px #a2a2a2;
  background: rgba(255, 255, 255, 0.7);
  padding: 1px 0px;
}

i.fa.fa-cloud-download {
  color: #ff0000;
  font-size: 18px;
}

i.fa.fa-cloud-download:hover {
  color: #000;
}

i.fa.fa-picture-o {
  color: #737373;
}

.button {
  margin-top: 20px
}

.gcenter {
  text-align: center !important;
}

.gal_cover {
  width: 100%;
  height: 225px;
  object-fit: fill;
  object-position: center;
}

/***********Sub Gallery Magnify Modal*************/

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}


/*******News********/
.nav-tabs .active {
  background-color: #ffffff;
  border-radius: 4px 4px 0 0;
}

.NEI-Cover {
  object-fit: contain;
  object-position: center;
  width: 100px;
  height: 100px;
  float: left;
  margin: 0px 20px 0px 0px;
  background: #efefef;
}

.news_title {
  float: left;
  font-size: 15px;
}

.news_details {
  float: right;
}

.clr {
  clear: both;
}

.att-link {
  color: #40403f;
}

.evntttlcol {
  font-size: 14px;
}

.newsmenu {
  padding-left: 0px;
  padding-right: 0px;
}

.next-arow {
  color: #8a8a8a;
  font-size: 27px;
}

.red-icon {
  color: #4aa4de;
}

.newsmenu {
  padding-left: 0px;
  padding-right: 0px;
}

.news-tab-head {
  color: #811010;
  font-size: 20px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-transform: capitalize;
  font-family: 'Roboto', sans-serif;
}

.next-arow {
  color: #8a8a8a;
  font-size: 27px;
}

.panel-body p {
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto-Regular', sans-serif;
}

.panel-body h5 {
  font-size: 16px;
}

.panel-body h5 i {
  font-size: 12px;
}

/*****Upcoming Events******/
.achv {
  height: 220px;
  width: 100%;
  object-fit: fill;
  object-position: center;
  box-shadow: 4px 3px 10px 0px #afafaf;
}

.evehead {
  font-size: 35px;
  color: #101010;
  font-family: 'Roboto-Thin', sans-serif;
  margin-bottom: 15px;
  font-weight: 600
}

.evetitlesec {
  border: 1px solid #fbfbfb;
  padding: 10px;
  background: white;
  box-shadow: 0px 7px 10px 0px #afafaf;
}

.maindatesec {
  /* background: url(https://webapi.entab.info/api/image/AFGJI/public/Images/topography.png) repeat; */
  height: 170px;
  border: 1px solid #fbfbfb;
  box-shadow: 0px 7px 10px 0px #afafaf;
}

.evelftdate {
  font-size: 80px;
  color: #232323;
  text-align: center;
  padding-top: 62px;
  padding-bottom: 15px;
}

.datetitle {
  text-align: center;
  font-size: 17px;
}

.evebgtitle {
  color: white;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  margin-top: 5px;
}

.daystitle { 
  height: 50px;
}

.blue {
  color: #d61417;
}

.evetitle {
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.evebox {
  margin: 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1.5em;
  line-height: 24px;
  text-overflow: ellipsis;
  font-size: 16px;
  color: #6f6f6f;
}

.evetitleleft {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.eveboxrgt {
  margin: 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.6em;
  line-height: 23px;
  text-overflow: ellipsis;
  font-size: 16px;
  margin-bottom: 16px;
  color: #6f6f6f;
}

.everytl {
  border-top: 3px solid #d61417;
  font-size: 20px;
  color: #000000;
  text-align: center;
  padding: 5px; 
  margin-bottom: 0px;
  font-weight: bold;
}

.rgtbox {
  border: 1px solid #d6d6d6;
  padding: 10px;
  background: white
}

.rytdate {
  background: #fff;
  height: 40px;
}

.subtl {
  font-size: 15px;
  color: #6f6f6f;
}

.sub_head {
  color: #4aa4de;
  font-size: 20px;
  margin-top: 10px;
  text-transform: capitalize;
}

.panel-heading {
  background: #eaeff3;
}

.media_gal_sec img {
  height: 220px;
  width: 100%;
  object-fit: contain;
  object-position: top;
  padding: 10px;
  padding: 15px;
  background: black;
}

.media_gal_sec .column1 {
  margin-bottom: 20px;
  border: solid 5px #eee;
}
span.bounce1 img { width: 22px; }
.OnlineR a { color: #fff; }
a.parantlogo { padding: 2px 10px; }
.OnlineR {position: absolute;right: 0;z-index: 99;text-align: center;color: #fff;bottom: 30px;}
@keyframes bounce1 { 
0% {
    background-color: #0781d2;
}
33% {
    background-color: #3d60ad;
}
66% {
    background-color: #0781d2;
}
100% {
    background-color: #3d60ad;
}
}
.bounce1 { border-radius: 25px 0px 0px 25px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }


.slidernotify {
  position: relative;
}
/************************************* parent-portal *******************************************************************/
.head3 {
  color: #20488a;
  font-size: 23px;
  margin-bottom: 20px;
}

.parent-link {
  color: #0f5f20;
  font-weight: 600;
}

.details {
  line-height: 27px;
  font-size: 16px;
  color: #4e4e4e !important;
}

.details a {
  color: #1f1983;
}

.imgheading {
  font-size: 18px;
  text-transform: none;
  color: #005919;
  margin-top: 0px;
  text-align: center;
  margin-bottom: 20px;
  text-decoration: none;
}

.clr25 {
  clear: both;
  height: 25px;
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.sub-head-1 {
  margin-bottom: 13px;
  font-size: 20px;
  color: #3695d2;
  margin-top: 10px;
}

.sub-head-1 a {
  color: #0388e0;
}

.clr10 {
  height: 10px;
  clear: both;
}

/********Animations Modal Css school ERP***********/
#myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {
  opacity: 0.7;
}

.animodal {
  display: none;
  position: fixed;
  z-index: 99999999999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

.animodal-content {
  margin: auto;
  display: block;
}

.animodal-content, #anicaption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

#anicaption, #anicaption2, #anicaption3 {
  margin: auto;
  display: block;
  text-align: center;
  color: #fff;
  padding: 10px 0;
  height: 150px;
  font-size: 24px;
  text-transform: capitalize;
}

.close {
  outline: none;
  position: absolute;
  top: 15px;
  right: 35px;
  font-size: 30px;
  font-weight: bold;
  transition: 0.3s;
  opacity: 0.5;
  color: white;
}

@-webkit-keyframes zoom {
  from {
      -webkit-transform: scale(0);
  }

  to {
      -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
      transform: scale(0)
  }

  to {
      transform: scale(1)
  }
}

.close {
  outline: none;
  position: absolute;
  top: 15px;
  right: 35px;
  font-size: 30px;
  font-weight: bold;
  transition: 0.3s;
  opacity: 0.5;
  color: white;
}
.navbar-nav{
  flex-direction: row;
}
.acheivement_blk{
  width: 100% !important;
}
.mandatory_dis thead {
  background: #d6efff;
  text-transform: uppercase;
}

.mandatory_dis h4 {
  text-transform: uppercase;
  color: #cf9217;
  font-weight: 400;
}

@media only screen and (max-width: 700px) {
  .modal-content {
      width: 100%;
      height: auto;
  }

  .animodal-content, #anicaption {
      width: 100%;
  }
}

/********************************************************left tab menu ******************************************/
.leftmenu {
  background-color: #f3f3f3;
  border: solid 1px #2f3d62;
  padding-left: 0px;
  padding-right: 0px;
  height: max-content;
}

.leftmenubg {
  background: #334164;
  height: 40px;
}

.menuhead {
  font-size: 19px !important;
  color: white !important;
  font-weight: 300;
  line-height: 37px;
  text-align: center !important;
}

.leftbtn {
  margin-top: 15px;
  padding-left: 0px;
  padding-right: 0px;
}

#events ul {
  margin: 0px;
  list-style-type: none;
}

#events ul li {
  padding: 3px 0px 3px 0px;
  font-family: verdana;
  font-size: 11px;
  line-height: 20px;
  border-bottom: dotted 1px #0a1a3e;
  margin-left: 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

#events ul li:hover {
  background-color: transparent;
}

#events ul li:hover{
  color: #FFFFFF;
}

#events ul li a {
  font-weight: bold;
  color: #3d3d3d;
  font-size: 11px;
  text-decoration: none;
  text-transform: uppercase;
  background: white;
}

#events ul li a:hover {
  color: #000000;
  text-decoration: none;
}

.word-tar {
  white-space: nowrap;
  width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-tabs {
  border-bottom: 2px solid #DDD;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  border-width: 0;
}

.nav-tabs > li > a {
  border: none;
  color: #666;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
  border: none;
  color: #151515 !important;
  background: #d6efff;
}

.nav-tabs > li > a::after {
  content: "";
  background: transparent;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0px;
  bottom: -1px;
  transition: all 250ms ease 0s;
  transform: scale(0);
}

.nav-tabs > li.active > a::after, .nav-tabs > li:hover > a::after {
  transform: scale(1);
}

.tab-nav > li > a::after {
  background: #21527d none repeat scroll 0% 0%;
  color: #fff;
}

.tab-pane {
  padding: 0px 0;
}

.tab-content {
  padding: 5px
}

.card {
  background: #FFF none repeat scroll 0% 0%;
  margin-bottom: 30px;
}

#events .nav > li > a:hover, #events .nav > li > a:focus {
  text-decoration: none;
  background-color: transparent;
  background: #d6efff;
}

.syllabus a {
  color: black;
  font-size: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  vertical-align: middle;
  text-transform:uppercase;
}

.syllabus a:hover {
  color: #bf8a00;
  text-align: center;
}

.syllabus {
  background: #eaeaea;
  padding: 10px 28px;
  text-align: center;
  margin: 5px;
}

.yellow {
  color: #4e9ed2;
  padding-right: 4px;
  padding-left: 7px;
}

.pad-10 {
  padding: 10px;
}

.evntgallry {
  height: 164px !important;
  width: 100%;
  object-fit: contain;
  background: #e7e7e7;
  border: 1px solid #d6d6d6;
  padding: 0px;
  margin-bottom: 15px;
}

.news_mscrool {
  height: 300px;
  margin-top: 16px;
  margin-bottom: 16px;
}

/******Events*********/
.event-section {
  padding-top: 12px;
  padding-bottom: 15px
}

.event-section h1 {
  padding-bottom: 10px;
  text-align: center;
}

.event-section p {
  padding-bottom: 10px;
}

.event-section h1::after {
  background-color: #ec9d07;
  content: "";
  display: block;
  height: 2px;
  width: 60px;
  left: 0;
  margin: auto;
  background-position: center center;
  margin-top: 20px;
  margin-bottom: 13px;
}

.event-cover {
  object-fit: contain;
  object-position: center;
  background: #f1f1f1;
  width: 100%;
  height: 250px !important;
  border: solid 1px #dad6d6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.btncenter {
  border-radius: 21px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #ffffff;
  background-color: #ff9f20;
  font-weight: 600;
  border: solid 2px #dd8c20;
}

.btncenter:hover {
  color: #fff;
  background-color: #f3900e;
  border-color: #f3900e;
}

.btncenter:active:focus {
  color: #fff;
  background-color: #4e9ed2;
  border-color: #4e9ed2;
}

.event-box-text {
  margin-bottom: 15px;
  border: 1px solid #e0e4e8;
  height: 170px;
  margin-top: -24px;
  border-top: 0px;
  transition: all 500ms ease;
}

.event-box-text:hover {
  /* border-color: #0984d2; */
  transition: all 500ms ease;
  box-shadow: 2px 1px 13px 0px #ddd9d9;
}

.event-box-text h5 {
  color: #363738;
  line-height: 20px;
}

.event-box-text .bi , .bi , .bi  {
  color: #4e9ed2;
  padding-left: 5px;
  padding-right: 5px;
}

.event-box-text p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1.8em;
  text-overflow: ellipsis;
  color: black;
  text-align: center;
}
.nav li.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in-out;
}

.dropdown-menu {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute !important;
}
.container-fluid.bgimage {
  margin-top: -5px;
}
div#myCarousel .carousel-indicators {
    display: none;
}

.event-box-text .att {
  text-align: center;
  padding: 0px;
  color: #ffffff;
  background: #418ebf;
  width: 42px;
  margin-top: 0px;
  margin: 0 auto;
  box-shadow: 0px 3px 8px 1px #a79f9f52;
  position: relative;
}

.event-box-text .att i {
  padding: 10px;
  font-size: 16px;
  color: white;
}

.one-bor h3 {
  font-size: 18px;
  color: #322f2f;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 1em;
  font-weight: bold;
}

.text-left {
  text-align: left !important;
}

.abutton {
  color: white;
  font-size: 18px;
  background: #28375e;
  padding: 5px 23px;
  margin-bottom: 15px;
  border-radius: 15px;
  line-height: 45px;
}

.abutton:hover {
  color: white;
  background: #e34e51;
}

.media-head {
  font-size: 15px;
  color: white;
  padding: 5px 10px 10px;
  margin-bottom: 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 6em;
  line-height: 21px;
  text-align: center;
  background: #172242;
  margin: 5px 0px 0px 0px !important;
  vertical-align: middle;
}




/* dynamic pages code */


.row.tabs-dynamic {
  background: #ebebeb;
  padding: 13px 0;
  margin-top: 40px;
}

.row.tabs-dynamic p {
  margin-bottom: 0;
}

.row.tabs-dynamic input, .row.tabs-dynamic select {
  border: 1px solid #ddd;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
}


.eventbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 13px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;
  height: 90%;
}

.eventbox .eventboximg {
  width: 27%;
}

.eventbox .eventboxdesc {
  width: 70%;
}

.eventbox .eventboximg img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  background: #ebebeb;
}

.eventbox .eventdate {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.eventbox .eventdate i {
  margin-right: 5px;
  color: #2e949b;
}

.eventbox .eventattch {
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
  color: #fff;
  padding: 2px 6px;
}
.evt-img > img {
  height: 180px;
  object-fit: contain;
  width: 100%;
  background: #e9e9e9;
}
.body_height .news-blk {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
}
.news-blk .attachment i{
  color: #fff;
}
.news-blk .news-head {
  width: 13%;
}

.news-blk .news-content {
  width: 84%;
}

.news-blk  .attachment {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
  color: #fff;
  padding: 3px 7px;
}
.float-md-start.msgimg {
  text-align: center;
  color: #fff;
}
.float-md-start.msgimg { 
  background: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
  padding: 7px;
  border-radius: 15px;
  box-shadow: 2px 50px 14px -40px #484848;
  margin-right: 20px;
  width: 300px;
  margin-bottom: 30px;
}
.float-md-start.msgimg img{
  width: 100%;
}
.news-blk .news-head {
  text-align: center;
  background: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
  color: #fff;
  padding: 27px 5px;
}

.news-blk .news-head span {
  display: block;
  text-transform: uppercase;
}

.news-blk .news-content h3 {
  font-size: 19px;
}

.news-blk .news-content p {
  margin-bottom: 0;
  font-size: 14px;
}

.news-blk .news-head h2 {
  font-size: 24px;
}

.gal_new {
  padding: 10px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  position: relative;
  height: 95%;
}

.gal_new img.img-fluid {
  height: 210px;
  object-fit: contain;
  background: #dbdbdb;
}

.gal_new h4.catgry-name {
  font-size: 20px;
  margin-top: 20px;
} 

.gal_new .total-img {
  background: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  padding:  5px 10px;
  box-shadow: 7px 5px 11px -10px #000;
}

.gal_new .total-img p {
  margin-bottom: 0;
} 

.gal_new h6.date {
  font-size: 14px;
}

.gal_new h6.date span {
  float: right;
  font-size: 20px;
}
.sub_gallery img{
  height: 200px;
  background: #ddd;
  object-fit: contain;
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
} 
.gal_new img {
  width: 100%;
} 
.gal_new p{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.gal_new .total-img i {
  color: #fff;
}
.discription {
    text-align: center;
    margin-top: 20px;
}

.discription h3.subhead {
    font-size: 22px;
    color: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
}
.video-gallery a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.video-gallery a i {
  font-size: 30px;
  margin: 20px;
  color: red;
}

.video-gallery {
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 5px;
}

.video-gallery a h3 {
  font-size: 18px;
}

.video-gallery a p {
  margin-bottom: 0;
  font-size: 13px;
}

.popup {
  padding-right: 0 !important;
} 
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:40%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #0579c6; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain; }
.popup .carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.popup .carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.popup .carousel-item .modalcard .card-body { padding: 10px }
.popup .carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.popup .carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.popup .carousel-item button.btn-close:focus { box-shadow: none }
.popup .carousel-item a.btn.btn-primary.btn-sm { border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a; 
  transition: .5s; background: linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%); border: #0579c6; margin: 0 10px; color: #fff!important ;     padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;}
  .popup .carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #FE621D; }
  .popup .carousel-item a.btn.btn-primary.btn-sm:hover {
  background:  linear-gradient(347deg, rgba(7,130,212,1) 0%, rgba(5,121,198,1) 100%);
}
div#lightboxContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background: #000000d1;
  padding-top: 2%;
}
.show{
  opacity: 1;
}
.imageModal {float: right;
  z-index: 99;
  position: relative;}
.button.online-registration a {
  display: flex;
}
.galleryimgbox img.img-fluid {
  height: 300px;
  object-fit: contain;
  background: #ddd;
  border-radius: 46px;
}
.popup .carousel-item button.btn-close {position: absolute;
  z-index: 999;
  background-color: #ffc268;
  right: 0;
  opacity: 1;
  color: #fff;
  filter: invert(1);
  padding: 9px;}

.popup .modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center;  }
.popup .modal-bottom a{
  margin-top: 30px;
}
.popup .carousel-item .modalcard{
  margin-bottom: 0;
}
/* dynamic pages code */
ul.toplink.special-wing img {
  width: 29px;
}

ul.toplink.special-wing {
  color: #fff;
  margin: 0 0 10px;
  font-size: 16px;
}

ul.toplink.special-wing li {
  margin-left: 6px;
}
.special-admission {
  position: absolute;
  top: 60px;
  color: #fff;
  padding: 10px;
  width: 250px;
  border-radius: 5px;
  left: 0;
  text-align: center;
  animation: 2s blinker1 infinite alternate;
  font-weight: 600;
}
@keyframes blinker1{
  0%{
      background: #0463a1;
  }
  100%{
      background: #fdae1d;
  }
}
ul.toplink.special-wing li.blinker {
  animation: 2s blinkerText infinite alternate;
}
@keyframes blinkerText{
  0%{
      color: orange;
  }
  100%{
      color: #63c0ff;
  }
}
 
.spl_blk {
  background: #f5f5f5;
  padding: 14px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px 0px #00000030;
  height: 98%;
}

.spl_blk h3 {
  text-align: center;
  font-size: 20px;
  color: #0781d2;
}

.spl_blk  p {
  margin-bottom: 0;
}
img.img-fluid.special_img {
    height: 250px;
    object-fit: contain;
    width: 100%;
    background: #ddd;
    border: 6px solid #fff;
    box-shadow: 0px 0px 5px 0px #ddd;
    margin-bottom: 20px;
} 

ol.list_none li {
    background: no-repeat;
    padding: 0 0 0 4px;
}
.admission_blk img {
  min-width: 40px;
  margin-right: 10px;
  max-width: 40px;
}

.admission_blk a {
  display: flex;
  color: unset;
  align-items: center;
}

.admission_blk a > div {
  margin-right: 10px;
}

.admission_blk {
  background: #efefef;
  padding: 15px 14px 22px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 0px 20px 20px -25px #000000bf;
  height: 78%;
  font-weight: 500;
  border: 1px solid #ddd;
}
.margin_tb{
  margin: 40px auto 60px;
}
/*****Media quiry*******/
@media(max-width: 1600px){
  .special-admission {
    font-size: 14px;
    position: absolute;
    top: 49px;
    color: #fff;
    padding: 9px 1px;
    width: 150px;
    border-radius: 5px;
    left: 0;
    text-align: center;
    animation: 2s blinker1 infinite alternate;
    
}
}
@media(max-width: 1400px){
  
  .special-admission {
    padding: 7px;
    position: static;
    width: 100%;
    margin-top: -7px;
    text-transform: uppercase;
}
  /* .special-admission {
    padding: 7px;
    width: 160px;
    top: 200px;
    z-index: 99;
} */
}
@media (max-width: 1299px) {

.erp_links {
  right: 2px;
  top: 100px;
}
}
@media (max-width: 1199px) {
  /* .special-admission {
    padding: 7px;
    width: 160px;
    top: 240px;
    z-index: 99;
} */
  .erp_links {
  right: 2px;
  top: 90px;
}
  .nav > li > a {
      padding: 10px 6px;
      font-size: 14px;
  }

  .news_mscrool {
      height: 182px;
      margin-top: 10px;
      margin-bottom: 10px;
  }

  .slider {
      height: 400px !important;
  }
}

@media (max-width: 991px) {
  .special-admission { 
    top: 160px;
    z-index: 99;
}
  .onlinepay {
      top: 140px;
  }

  .toplink li a {
      padding-top: 0px;
  }

  .nav > li > a {
      padding: 11px 3px;
      font-size: 11px;
  }

  .news_area h4 {
      margin-top: 0px;
      text-align: center;
      color: white;
      padding-top: 13px;
  }

  .pad_box_right {
      padding-right: 45px;
      padding-left: 40px;
  }

  .space {
      padding-bottom: 15px;
  }

  .slider {
      height: auto !important;
  }

  .mobile_app_link h3 {
      text-align: center;
  }

  .menu .nav > li > a, .dropdown-menu > li > a {
      font-size: 14px;
  }

  .gallerygrid {
      width: 33%;
      padding: 0px 10px;
  }

  .column {
      width: 33%;
  }

  .toplink {
      float: none;
      text-align: center;
  }

  .address {
      text-align: center;
  }

  .circularbox h4 {
      color: #192b53;
  }

  .event-cover {
      object-fit: fill;
      object-position: top;
      width: 100%;
      height: 150px !important;
  }
}

@media (max-width: 767px) {
 
  .special-admission {
    position: static;
    width: 100%;
    z-index: 99;
}
  .onlinepay {
      top: 285px;
  }
  .navbar-nav {
    flex-direction: column;
}
  .nav > li > a {
      text-align: left;
      font-size: 14px;
  }

  .menu .nav > li > a, .dropdown-menu > li > a {
      font-size: 15px;
  }

  .toplink li a {
      float: none;
  }

  .toplink {
      text-align: center;
      float: none;
  }

  .menu_sec {
      padding: 0px 0px;
      position: relative;
      z-index: 9;
  }

  .navbar-toggle {
      margin-top: -45px;
      margin-right: 1px;
  }

  .address {
      float: left;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
  }

  .address span {
      float: none;
      padding-top: 0px;
      padding-left: 8px;
      text-align: center;
  }

  .pad_box_left {
      padding-left: 0px;
      padding-right: 0px
  }

  .pad_box_right {
      padding-left: 0px;
      padding-right: 0px
  }

  .footer_sec_bottom p {
      line-height: 22px;
  }

  .modal-dialog {
      width: 95%;
  }

  .modal-body img {
      height: 380px !Important;
  }

  .co-space {
      padding-bottom: 15px;
  }

  .innerhead {
      font-size: 27px;
      color: #3b98d4;
      padding: 3px 0px;
      text-align: center;
      margin-top: 10px;
  }

  .breadcrumb {
      margin: 0;
      background-color: transparent;
      text-transform: capitalize;
      line-height: 0px;
      float: none;
      text-align: center;
      padding-bottom: 20px;
  }

  .column {
      width: 50%;
  }

  .leftmenu {
      margin-right: 15px;
      margin-left: 15px;
  }

  .nav > li > a {
      border-left: none;
  }

  .toplink li a {
      padding-left: 8px;
  }
}

@media (max-width: 767px) {
  .erp_links {
  right: 2px;
  position: fixed;
  top: unset;
  z-index: 999;
  bottom: 10px;
}
  .body_height {
      padding-top: 40px;
  }

  .width_100 {
      width: 100%;
      margin: 0 auto;
  }

  .toplink {
      float: none;
      text-align: center;
  }

  .event-cover {
      height: 200px !important;
  }

  .news_details {
      float: left;
      padding-top: 4px;
  }

  .panel-ppad {
      text-align: center;
  }
}

@media (max-width: 600px) 
{
  div#exampleModalToggle iframe{
    min-height: 220px !important;
  }
  .dips-grid {
  width: 100%;
}
  
  .gallerygrid {
      width: 50%;
      padding: 0px 5px;
  }

  .width-100 {
      width: 100%;
      margin: 0 auto;
      padding-bottom: 12px;
  }

  .evntgallry {
      height: 250px !important;
  }

  .footer_grid {
      width: 100%;
      margin: 0 auto;
  }
}

.alumnisec img {
  border: 8px solid #192b53;
  border-radius: 3px;
  display: block;
  margin: 0 auto;
}

.alumnisec p {
  margin-top: 20px;
  font-size: 16px;
  padding: 0px 5px;
}

.heading-border-line {
  background: #d9d6d6;
  height: 3px;
  width: 61px;
margin: 0 auto;
  margin-bottom: 15px;
}